<template>
  <b-card
    :title="$t('SOLDE.PAGE_TITLE')"
  >
    <div v-if="can_access">
      <b-overlay
        :show="loading"
      >
        <b-card-text>{{ $t('SOLDE.SUBTITLE') }}</b-card-text>
        <b-row>
          <b-col cols="12">
            <!--
            <div class="custom-search d-flex justify-content-end">
              <b-form-group>
                <div class="d-flex align-items-center">
                  <label class="mr-1">Search</label>
                </div>
              </b-form-group>
            </div>
            -->

            <vue-good-table
              :columns="columns"
              :rows="rows"
              :sort-options="{enabled: false, }"
            >
              <template
                slot="table-column"
                slot-scope="props"
              >
                <span>
                  {{ $t(`SOLDE.TABLE_HEADER.${props.column.label}`) }}
                </span>
              </template>

              <div
                id="emptySlot"
                slot="emptystate"
              >
                {{ $t('SOLDE.NO_DATA') }}
              </div>

              <template
                slot="table-row"
                slot-scope="props"
              >
                <span v-if="props.column.field == 'type'">
                  <span v-if="props.row.type == 'retrait'">
                    <b-badge variant="light-danger">
                      {{ $t(`SOLDE.${props.row.type}`) }}
                    </b-badge>
                  </span>
                  <span v-else>
                    <b-badge variant="light-success">
                      {{ $t(`SOLDE.${props.row.type}`) }}
                    </b-badge>
                  </span>
                </span>
                <span v-else-if="props.column.field == 'solde'">
                  <span
                    style="font-weight: bold;"
                    :class="textSoldeClass(props.row.solde)"
                    class="text-right"
                  >{{ props.row.solde }}
                  </span>
                </span>

                <span v-else-if="props.column.field == 'libelle'">
                  <span v-if="hotline(props.row)">
                    <router-link
                      :to="{ name: 'request-detail', params: { id: props.row.idHotline.idHotline }}"
                      class="font-weight-bold text-primary"
                    >
                      {{ hotline(props.row) }}
                    </router-link>

                  </span>
                  <span v-else>
                    {{ props.row.libelle }}
                  </span>
                </span>

                <span v-else-if="props.column.field=='date'">
                  {{ props.row.date | luxon("dd/MM/yyyy") }}
                </span>

                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>

              </template>

            </vue-good-table>

          </b-col>
        </b-row>

        <div class="mt-1">
          <b-row>
            <b-col cols="6">
              <div class="d-flex align-items-center">
                <span class="text-nowrap "> {{ $t("PAGINATION.DISPLAY") }} </span>
                <v-select
                  v-model="limit"
                  label="limit"
                  :clearable="false"
                  :options="[5,10,25,50,100]"
                  class="ml-1"
                />
              </div>

            </b-col>
            <b-col cols="6">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalItem"
                :per-page="limit"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                align="right"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </div>
    <notAuthorized v-else />
  </b-card>
</template>

<script>
import {
  BOverlay, BCard, BCardText, BBadge, BRow, BCol, BPagination,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import NotAuthorized from '@/views/auth/NotAuthorized.vue'

export default {
  components: {
    VueGoodTable,
    BOverlay,
    BCard,
    BCardText,
    BBadge,
    BRow,
    BCol,
    BPagination,
    vSelect,
    NotAuthorized,
  },
  data() {
    return {
      loading: false,
      currentPage: 1,
      limit: 20,
      totalPage: 0,
      totalItem: 0,
      columns: [
        { field: 'date', label: 'date' },
        { field: 'type', label: 'type' },
        { field: 'nbreTickets', label: 'nbCredit' },
        { field: 'libelle', label: 'libelle' },
        { field: 'solde', label: 'solde' },
      ],
      rows: [],
      can_access: false,
    }
  },
  computed: {
    activeSociete() {
      return this.$store.getters['societe/getActiveSociete']
    },
  },
  watch: {
    currentPage() {
      this.getSolde()
    },
    limit() {
      if (this.currentPage === 1) {
        this.getSolde()
      } else {
        this.currentPage = 1
      }
    },
    activeSociete(newActiveSociete, oldActiveSociete) {
      this.authorized()
      if (newActiveSociete.idSociete !== oldActiveSociete.idSociete) {
        this.getSolde()
      }
    },
    can_access() {
      if (this.can_access) {
        this.getSolde()
      }
    },
  },
  mounted() {
    this.authorized()
  },
  methods: {
    authorized() {
      this.$store.dispatch('application/canAccess', { idSociete: this.activeSociete.idSociete, droit: 'solde' }).then(r => { this.can_access = r })
    },
    getSolde() {
      this.loading = true

      this.$store.dispatch('societe/getEvolutionSolde', { idSociete: this.activeSociete.idSociete, limit: this.limit, page: this.currentPage })
        .then(result => {
          this.totalItem = result.total
          this.page = result.page
          this.totalPage = result.total_page
          this.rows = result.data
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
    },
    hotline(row) {
      if (row.idHotline !== null) {
        return `#${row.idHotline.idHotline} - ${row.idHotline.hotline}`
      }
      return false
    },
    textSoldeClass(solde) {
      if (solde < 0) {
        return 'text-danger'
      } if (solde < 3) {
        return 'text-warning'
      }
      return 'text-success'
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
